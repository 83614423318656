// src/App.js
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import { ToastContainer } from "react-toastify";
import { routeConfig } from "../config/route_config";
import NotFound from "../components/not_found";

function App() {
  const renderRoutes = (routes) => {
    return routes.map((route) => {
      if (route.children) {
        return (
          <Route key={route.path} path={route.path} element={<route.element />}>
            {renderRoutes(route.children)}
          </Route>
        );
      }
      return (
        <Route
          key={route.path || "index"}
          index={route.index}
          path={route.path}
          element={<route.element />}
        />
      );
    });
  };

  return (
    <>
      {/* Global Loader */}
      <div id="loader" className="loaderDiv !hidden">
        <div>
          <img
            src="/assets/img/loader.gif"
            alt="loader"
            className="pageLoader"
            width="auto"
            height="auto"
          />
        </div>
      </div>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          {renderRoutes(routeConfig)}
          {/* 404 Page */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
