import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { KEY_LOGIN_RESULT } from "../utils/constants";
import SideNav from "./side_nav";
import MainContent from "./main_content";
import ROUTES from "../config/routes";

// Layout Component
const MainLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loginResult, setLoginResult] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedLoginResult = JSON.parse(
      localStorage.getItem(KEY_LOGIN_RESULT)
    );
    if (storedLoginResult === null) {
      navigate(ROUTES.LOGIN);
    }
    if (storedLoginResult) {
      setLoginResult(storedLoginResult);
    }
  }, []);

  return (
    <div className="flex h-screen bg-slate-50">
      {/* Sidebar */}
      <SideNav isSidebarOpen={isSidebarOpen} />

      {/* Main Content */}
      <MainContent
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        loginResult={loginResult}
      >
        <Outlet />
      </MainContent>
    </div>
  );
};

export default MainLayout;
