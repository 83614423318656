const loader = (p) => {
  if (p) {
    try {
      document.getElementById("loader").classList.remove("!hidden");
    } catch {}
  } else {
    try {
      document.getElementById("loader").classList.add("!hidden");
    } catch {}
  }
};

export default loader;
