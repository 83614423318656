import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronDown } from "lucide-react";
import ROUTES from "../../config/routes";

const AddOrganizationContract = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    role: "",
    dob: "",
    gender: "",
    email: "",
    about: "",
    address: "",
    is_email_verified: "Not Verified",
    is_mobile_verified: "Not Verified",
    is_active: "Inactive",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };

  const redirectToOrganizationContracts = () => {
    navigate(ROUTES.ORGANIZATIONS.CONTRACTS.LIST);
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Contracts</h1>
          <p className="text-sm text-gray-500">
            <span>Organization Control - </span>
            <span
              onClick={redirectToOrganizationContracts}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Contracts
            </span>
            <span> / Add OrganizationContract</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">Add user</h2>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* First Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter first name"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              {/* Last Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Enter last name"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              {/* Mobile Number */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Mobile Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="mobileNumber"
                  placeholder="Enter mobile number"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              {/* Role */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Role <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <select
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                    <option value="manager">Manager</option>
                  </select>
                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
              </div>

              {/* Date of Birth */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Date of Birth <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              {/* Gender */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Gender <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
              </div>

              {/* Email */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
            </div>

            {/* About */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                About
              </label>
              <textarea
                name="about"
                placeholder="Tell us about yourself..."
                value={formData.about}
                onChange={handleChange}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Address */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <textarea
                name="address"
                placeholder="Enter your address..."
                value={formData.address}
                onChange={handleChange}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Verification Status Section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
              {/* Email Verification Status */}
              <div className="w-full">
                <label className="block mb-4 text-sm font-medium text-gray-700">
                  Email Verification Status
                </label>
                <div className="flex">
                  <div className="flex items-center mr-4">
                    <input
                      id="email_verified_1"
                      type="radio"
                      name="is_email_verified"
                      value="Verified"
                      checked={formData.is_email_verified === "Verified"}
                      onChange={handleChange}
                      className="w-5 h-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    />
                    <label
                      htmlFor="email_verified_1"
                      className="block ml-2 text-sm font-medium text-gray-900"
                    >
                      Verified
                    </label>
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      id="email_verified_2"
                      type="radio"
                      name="is_email_verified"
                      value="Not Verified"
                      checked={formData.is_email_verified === "Not Verified"}
                      onChange={handleChange}
                      className="w-5 h-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    />
                    <label
                      htmlFor="email_verified_2"
                      className="block ml-2 text-sm font-medium text-gray-900"
                    >
                      Not Verified
                    </label>
                  </div>
                </div>
              </div>

              {/* Mobile Verification Status */}
              <div className="w-full">
                <label className="block mb-4 text-sm font-medium text-gray-700">
                  Mobile Verification Status
                </label>
                <div className="flex">
                  <div className="flex items-center mr-4">
                    <input
                      id="mobile_verified_1"
                      type="radio"
                      name="is_mobile_verified"
                      value="Verified"
                      checked={formData.is_mobile_verified === "Verified"}
                      onChange={handleChange}
                      className="w-5 h-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    />
                    <label
                      htmlFor="mobile_verified_1"
                      className="block ml-2 text-sm font-medium text-gray-900"
                    >
                      Verified
                    </label>
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      id="mobile_verified_2"
                      type="radio"
                      name="is_mobile_verified"
                      value="Not Verified"
                      checked={formData.is_mobile_verified === "Not Verified"}
                      onChange={handleChange}
                      className="w-5 h-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    />
                    <label
                      htmlFor="mobile_verified_2"
                      className="block ml-2 text-sm font-medium text-gray-900"
                    >
                      Not Verified
                    </label>
                  </div>
                </div>
              </div>

              {/* Active Status */}
              <div className="w-full">
                <label className="block mb-4 text-sm font-medium text-gray-700">
                  Active Status
                </label>
                <div className="flex">
                  <div className="flex items-center mr-4">
                    <input
                      id="active_status_1"
                      type="radio"
                      name="is_active"
                      value="Active"
                      checked={formData.is_active === "Active"}
                      onChange={handleChange}
                      className="w-5 h-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    />
                    <label
                      htmlFor="active_status_1"
                      className="block ml-2 text-sm font-medium text-gray-900"
                    >
                      Active
                    </label>
                  </div>
                  <div className="flex items-center mr-4">
                    <input
                      id="active_status_2"
                      type="radio"
                      name="is_active"
                      value="Inactive"
                      checked={formData.is_active === "Inactive"}
                      onChange={handleChange}
                      className="w-5 h-5 border-gray-300 focus:ring-2 focus:ring-blue-300"
                    />
                    <label
                      htmlFor="active_status_2"
                      className="block ml-2 text-sm font-medium text-gray-900"
                    >
                      Inactive
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className="flex justify-center ">
              {" "}
              {/* Changed from justify-end to justify-center */}
              <button
                type="submit"
                className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {/*<!-- End block -->*/}
    </div>
  );
};

export default AddOrganizationContract;
