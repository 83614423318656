import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Calendar,
  Clock,
  MapPin,
  Users,
  Building,
  Mail,
  Phone,
  Tag,
  Ticket,
  Music,
  ParkingCircle,
  UtensilsCrossed,
  Baby,
  Globe,
  Pencil,
  Trash,
  CheckCircle,
  XCircle,
} from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import ROUTES from "../../config/routes";
import DateFormatter from "../../utils/date_formatter";
import { getEventDetails } from "../../network/event_api";

const ViewEventDetails = ({ event, onEdit, onDelete }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventResult, setEventResult] = useState({});
  const [deletingEvent, setDeletingEvent] = useState(null);

  useEffect(() => {
    fetchEventDetails();
  }, []);

  const handleDeleteClick = (event) => {
    setDeletingEvent(event);
  };

  const handleCloseModal = () => {
    setDeletingEvent(null);
  };

  const confirmDelete = async () => {};

  const redirectToEvents = () => {
    navigate(ROUTES.EVENTS.LIST);
  };

  const editEventDetails = (event) => {
    //navigate(ROUTES.EVENTS.EDIT.replace(':id', event.id));
  };

  const fetchEventDetails = async () => {
    try {
      loader(true);
      const response = await getEventDetails(id);
      setEventResult(response.result);
      toast.success("Event Detail fetched successfully");
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Events</h1>
          <p className="text-sm text-gray-500">
            <span>Event Control - </span>
            <span
              onClick={redirectToEvents}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Events
            </span>
            <span> / {id}</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-8">
            Event Details
          </h2>

          <div className="flex flex-row mb-4">
            <img
              className="w-full h-48 object-cover rounded-lg bg-gray-300"
              src={eventResult.header_image}
              alt="event"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Event Name
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Calendar className="w-4 h-4 text-gray-500" />
                <span>{eventResult.name}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Duration Type
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Clock className="w-4 h-4 text-gray-500" />
                <span>
                  {eventResult.duration_type?.replace("_", " ").toUpperCase()}
                </span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Event Timing
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Clock className="w-4 h-4 text-gray-500" />
                <span>
                  {DateFormatter.formatFullDate(
                    eventResult.event_start_date,
                    eventResult.event_start_time
                  )}{" "}
                  -{" "}
                  {DateFormatter.formatFullDate(
                    eventResult.event_end_date,
                    eventResult.event_end_time
                  )}
                </span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Venue
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <MapPin className="w-4 h-4 text-gray-500" />
                <span>{eventResult.event_venue_name}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Venue Address
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Building className="w-4 h-4 text-gray-500" />
                <span>{eventResult.event_venue_address || "--"}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Organization
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Building className="w-4 h-4 text-gray-500" />
                <span>{eventResult.organization_name}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Contact Email
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Mail className="w-4 h-4 text-gray-500" />
                <span>{eventResult.organization_email}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Contact Phone
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Phone className="w-4 h-4 text-gray-500" />
                <span>{eventResult.organization_mobile_number}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Category
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Tag className="w-4 h-4 text-gray-500" />
                <span>{eventResult.category_name}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Audience Type
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Users className="w-4 h-4 text-gray-500" />
                <span>{eventResult.audience_type?.toUpperCase()}</span>
              </p>
            </div>

            <div className="w-full flex flex-col col-span-2">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Description
              </h2>
              <p className="mb-4 font-medium text-gray-500">
                {eventResult.description || "--"}
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Amenities
              </h2>
              <div className="space-y-2">
                <p className="font-medium text-gray-500 flex items-center space-x-2">
                  <ParkingCircle className="w-4 h-4 text-gray-500" />
                  <span>Parking:</span>
                  {eventResult.is_parking_available ? (
                    <CheckCircle className="w-4 h-4 text-green-600" />
                  ) : (
                    <XCircle className="w-4 h-4 text-red-600" />
                  )}
                </p>
                <p className="font-medium text-gray-500 flex items-center space-x-2">
                  <UtensilsCrossed className="w-4 h-4 text-gray-500" />
                  <span>Food:</span>
                  {eventResult.is_food_available ? (
                    <CheckCircle className="w-4 h-4 text-green-600" />
                  ) : (
                    <XCircle className="w-4 h-4 text-red-600" />
                  )}
                </p>
                <p className="font-medium text-gray-500 flex items-center space-x-2">
                  <Baby className="w-4 h-4 text-gray-500" />
                  <span>Childcare:</span>
                  {eventResult.is_childcare_available ? (
                    <CheckCircle className="w-4 h-4 text-green-600" />
                  ) : (
                    <XCircle className="w-4 h-4 text-red-600" />
                  )}
                </p>
              </div>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Event Status
              </h2>
              <div className="space-y-2">
                <p className="font-medium text-gray-500 flex items-center space-x-2">
                  <Globe className="w-4 h-4 text-gray-500" />
                  <span>Published:</span>
                  <span
                    className={`px-2 py-1 text-xs font-medium rounded-full ${
                      eventResult.is_published
                        ? "text-green-600 bg-green-100"
                        : "text-red-600 bg-red-100"
                    }`}
                  >
                    {eventResult.is_published ? "Yes" : "No"}
                  </span>
                </p>
                <p className="font-medium text-gray-500 flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-gray-500" />
                  <span>Active:</span>
                  <span
                    className={`px-2 py-1 text-xs font-medium rounded-full ${
                      eventResult.is_active
                        ? "text-green-600 bg-green-100"
                        : "text-red-600 bg-red-100"
                    }`}
                  >
                    {eventResult.is_active ? "Yes" : "No"}
                  </span>
                </p>
              </div>
            </div>

            {eventResult.ticket_pricings?.length > 0 && (
              <div className="w-full flex flex-col col-span-2">
                <h2 className="mb-4 font-medium leading-none text-gray-700">
                  Ticket Information
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {eventResult.ticket_pricings.map((ticket, index) => (
                    <div key={index} className="p-4 border rounded-lg">
                      <div className="flex items-center space-x-2 mb-2">
                        <Ticket className="w-4 h-4 text-gray-500" />
                        <span className="font-medium">{ticket.name}</span>
                        <span className="px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-600">
                          {ticket.ticket_category?.toUpperCase()}
                        </span>
                      </div>
                      <div className="space-y-2">
                        <p className="text-gray-500">Price: ₹{ticket.price}</p>
                        <p className="text-gray-500">
                          Available: {ticket.remaining_quantity}/
                          {ticket.total_quantity}
                        </p>
                        {ticket.description && (
                          <p className="text-gray-500">
                            Description: {ticket.description}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {eventResult.artists?.length > 0 && (
              <div className="w-full flex flex-col col-span-2">
                <h2 className="mb-4 font-medium leading-none text-gray-700">
                  Featured Artists
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {eventResult.artists.map((artist, index) => (
                    <div key={index} className="p-4 border rounded-lg">
                      <div className="flex items-center space-x-2 mb-2">
                        <Music className="w-4 h-4 text-gray-500" />
                        <span className="font-medium">{artist.name}</span>
                      </div>
                      <p className="text-gray-500">{artist.bio}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-row space-x-4 mt-8">
            <button
              onClick={() => editEventDetails(eventResult)}
              type="button"
              className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Pencil className="w-5 h-5 mr-1.5 -ml-1" />
              Edit
            </button>
            <button
              onClick={() => handleDeleteClick(eventResult)}
              type="button"
              className="inline-flex items-center text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Trash className="w-5 h-5 mr-1.5 -ml-1" />
              Delete
            </button>
          </div>
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this event"}
        isOpen={deletingEvent}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ViewEventDetails;
