import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  Download,
  MoreVertical,
  Pencil,
  Trash2,
  Eye,
  Plus,
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
} from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import TablePagination from "../../components/table_pagination";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getAllEventCategories } from "../../network/event_api";
import DateFormatter from "../../utils/date_formatter";

const EventCategories = () => {
  const navigate = useNavigate();
  const [openMenuId, setOpenMenuId] = useState(null);
  const [deletingEventCategory, setDeletingEventCategory] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [eventCategoryListResult, setEventCategoryListResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "",
    order: "",
  });
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (id) => {
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const confirmDelete = async () => {};

  const handleDeleteClick = (category) => {
    setDeletingEventCategory(category);
  };

  const handleCloseModal = () => {
    setDeletingEventCategory(null);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getEventCategoryList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, itemsPerPage, searchTerm, sortConfig]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleSort = async (key) => {
    let newOrder = "asc";

    if (sortConfig.key === key) {
      if (sortConfig.order === "asc") {
        newOrder = "desc";
      } else if (sortConfig.order === "desc") {
        // Reset sorting
        key = "";
        newOrder = "";
      }
    }

    setSortConfig({
      key: key,
      order: newOrder,
    });
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown className="inline ml-2 h-4 w-4" />;
    }
    return sortConfig.order === "asc" ? (
      <ArrowUp className="inline ml-2 h-4 w-4" />
    ) : (
      <ArrowDown className="inline ml-2 h-4 w-4" />
    );
  };

  const getEventCategoryList = async () => {
    try {
      loader(true);
      const response = await getAllEventCategories({
        page: currentPage,
        limit: itemsPerPage,
        search: searchTerm,
        sort_key: sortConfig.key,
        sort_order: sortConfig.order,
      });
      setTotalCount(response.pagination.total);
      setEventCategoryListResult(response.result);
      toast.success("List fetched successfully");
    } catch (error) {
      if (error.code === 404) {
        setTotalCount(0);
        setEventCategoryListResult([]);
      } else {
        // Show error toast for other errors
        toast.error(error.message || "Operation failed");
      }
    } finally {
      loader(false);
    }
  };

  const addEventCategory = () => {
    navigate(ROUTES.EVENTS.CATEGORIES.CREATE);
  };

  const viewEventCategoryDetails = (cat) => {
    navigate(ROUTES.EVENTS.CATEGORIES.VIEW.replace(":id", cat.id));
  };

  const editEventCategoryDetails = (cat) => {
    navigate(ROUTES.EVENTS.CATEGORIES.EDIT.replace(":id", cat.id));
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">
            Event Categories
          </h1>
          <p className="text-sm text-gray-500">
            Event Control - Event Categories
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm">
          <div className="p-4 flex justify-between items-center">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="pl-10 pr-4 py-2 bg-gray-100 rounded-lg w-80 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="flex gap-3">
              <button className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 text-sm">
                <Download className="h-5 w-5" />
                Download
              </button>
              <button
                onClick={() => addEventCategory()}
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm"
              >
                <Plus className="w-5 h-5" />
                Add Event Category
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    S.NO.
                  </th>
                  <th
                    className="px-6 py-3 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                    onClick={() => handleSort("name")}
                  >
                    <div className="flex items-center">
                      NAME
                      {getSortIcon("name")}
                    </div>
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    DESCRIPTION
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    POPULARITY SCORE
                  </th>
                  <th
                    className="px-6 py-3 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                    onClick={() => handleSort("created_at")}
                  >
                    <div className="flex items-center">
                      CREATED AT
                      {getSortIcon("created_at")}
                    </div>
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    ACTIONS
                  </th>
                </tr>
              </thead>
              {eventCategoryListResult.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {eventCategoryListResult.map((category, index) => (
                    <tr key={category.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {index + (currentPage - 1) * itemsPerPage + 1}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {category.name || "--"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        <div className="line-clamp-4">
                          {category.description || "--"}
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {category.popularity_score || "--"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {DateFormatter.formatFullDate(category.created_at)}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex justify-center relative">
                          <button
                            onClick={() => toggleMenu(category.id)}
                            className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
                          >
                            <MoreVertical className="h-5 w-5" />
                          </button>

                          {openMenuId === category.id && (
                            <div
                              className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                              style={{
                                transform: "translateY(calc(-50% + 2.5rem))",
                                top: "50%",
                              }}
                              ref={menuRef}
                            >
                              <div className="py-1">
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                                  onClick={() =>
                                    viewEventCategoryDetails(category)
                                  }
                                >
                                  <Eye className="h-4 w-4 mr-2" />
                                  View
                                </button>
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                                  onClick={() =>
                                    editEventCategoryDetails(category)
                                  }
                                >
                                  <Pencil className="h-4 w-4 mr-2" />
                                  Edit
                                </button>
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full"
                                  onClick={() => handleDeleteClick(category)}
                                >
                                  <Trash2 className="h-4 w-4 mr-2" />
                                  Delete
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No event category found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          {/* Pagination */}
          {eventCategoryListResult.length > 0 && (
            <TablePagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={itemsPerPage}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(newPageSize) => {
                setItemsPerPage(newPageSize);
                setCurrentPage(1);
              }}
              pageSizeOptions={[10, 20, 50]}
            />
          )}
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this event category?"}
        isOpen={deletingEventCategory}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default EventCategories;
