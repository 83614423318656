import Multiselect from "multiselect-react-dropdown";

function MultiselectDropdown({ formik, options, formikValue, placeholder }) {
  return (
    <Multiselect
      name={formikValue}
      placeholder={placeholder}
      options={options} // list of options to display
      displayValue="name" // the object `key` whose value we want to display
      // `selectedValues` is the array of objects representing the selected options.
      // Here we are filtering the `organizationList` to find the organizations whose IDs are in the formik state (`formik.organization_id`).
      selectedValues={options?.filter((opt) =>
        formik.values[formikValue]?.includes(opt.id)
      )}
      onSelect={(selectedList) =>
        formik.setFieldValue(
          formikValue,
          selectedList.map((opt) => opt.id)
        )
      }
      onRemove={(selectedList) => {
        formik.setFieldValue(
          formikValue,
          selectedList.map((opt) => opt.id)
        );
      }}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full"
      style={{
        chips: {}, // Clear styles for selected options (chips)
        searchBox: {
          padding: "0", // Remove padding for search box
          width: "100%", // Make search box take full available width
          color: "black", // Set the color of the placeholder text to black
        }, // Clear styles for the search box
        multiselectContainer: {
          width: "100%",
        }, // Clear styles for the outer container
        optionContainer: {}, // Clear styles for the dropdown menu
      }}
    />
  );
}

export default MultiselectDropdown;
