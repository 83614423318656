import React from "react";

const ArtistDisplay = ({ artists }) => {
  // If artists is undefined, null, or empty string, show placeholder
  if (!artists) {
    return <span className="text-gray-400">--</span>;
  }

  // Convert string to array if it's a comma-separated string
  const artistArray =
    typeof artists === "string"
      ? artists
          .split(",")
          .map((a) => a.trim())
          .filter(Boolean)
      : Array.isArray(artists)
      ? artists
      : [];

  if (artistArray.length === 0) {
    return <span className="text-gray-400">--</span>;
  }

  return (
    <div className="flex flex-wrap gap-1">
      {artistArray.map((artist, index) => (
        <span
          key={index}
          className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800"
        >
          {artist}
        </span>
      ))}
    </div>
  );
};

export default ArtistDisplay;
