import React from "react";

const RadioGroup = ({ label, name, options, formik }) => (
  <div className="space-y-2">
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <div className="flex gap-4">
      {options.map((option) => (
        <label key={option.value} className="inline-flex items-center">
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={formik.values[name] === option.value}
            onChange={formik.handleChange}
            className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500"
          />
          <span className="ml-2 text-sm text-gray-700">{option.label}</span>
        </label>
      ))}
    </div>
  </div>
);

export default RadioGroup;
