import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronDown } from "lucide-react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import { addUser } from "../../network/user_api";
import { getAllOrganizations } from "../../network/organization_api";
import { sortAlphabetically } from "../../utils/helper";

const roleDropdownOptions = [
  { name: "Admin", value: "admin" },
  { name: "User", value: "user" },
  { name: "Organizer Admin", value: "organizer_admin" },
  { name: "Organizer Staff", value: "organizer_staff" },
];

const genderDropDownOptions = ["Male", "Female", "Other"];

const AddUser = () => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const response = await getAllOrganizations({
        is_active: true,
      });
      if (response.code === 200) {
        setOrganizations(response.result);
      }
    } catch (error) {
      toast.error("Failed to fetch organizations");
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      mobile_number: "",
      role: "",
      dob: "",
      gender: "",
      email: "",
      about: "",
      address: "",
      organization_id: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.firstname) {
        errors.firstname = "First name is required";
      }

      if (!values.lastname) {
        errors.lastname = "Last name is required";
      }

      if (!values.mobile_number) {
        errors.mobile_number = "Mobile number is required";
      }

      if (!values.role) {
        errors.role = "Role is required";
      }

      // Add organization validation for organizer roles
      if (
        ["organizer_admin", "organizer_staff"].includes(values.role) &&
        !values.organization_id
      ) {
        errors.organization_id = "Organization is required for organizer roles";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const formData = {
          ...values,
          dob: values.dob || null,
          address: values.address || null,
          is_active: 1,
          organization_id: ["organizer_admin", "organizer_staff"].includes(
            values.role
          )
            ? values.organization_id
            : null,
        };
        const response = await addUser(formData);
        if (response.code === 200) {
          toast.success("User added successfully");
          setTimeout(() => {
            navigate(ROUTES.USERS.LIST);
          }, 500);
        } else {
          toast.error("Failed to add user");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  const redirectToUsers = () => {
    navigate(ROUTES.USERS.LIST);
  };

  const showOrganizationField = ["organizer_admin", "organizer_staff"].includes(
    formik.values.role
  );

  return (
    <div>
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Users</h1>
          <p className="text-sm text-gray-500">
            <span>Masters - </span>
            <span
              onClick={redirectToUsers}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Users
            </span>
            <span> / Add User</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">Add User</h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* First Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstname"
                  placeholder="Enter first name"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.firstname}
                  </div>
                )}
              </div>

              {/* Last Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastname"
                  placeholder="Enter last name"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.lastname}
                  </div>
                )}
              </div>

              {/* Mobile Number */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Mobile Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="mobile_number"
                  placeholder="Enter mobile number"
                  value={formik.values.mobile_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.mobile_number &&
                  formik.errors.mobile_number && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.mobile_number}
                    </div>
                  )}
              </div>

              {/* Email */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Role */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Role <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <select
                    name="role"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Role</option>
                    {sortAlphabetically(roleDropdownOptions).map(
                      ({ name, value }, index) => (
                        <option key={index} value={value}>
                          {name}
                        </option>
                      )
                    )}
                  </select>
                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
                {formik.touched.role && formik.errors.role && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.role}
                  </div>
                )}
              </div>

              {/* Organization (Conditional) */}
              {showOrganizationField && (
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Organization <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <select
                      name="organization_id"
                      value={formik.values.organization_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">Select Organization</option>
                      {organizations.map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                    </select>
                    <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                  </div>
                  {formik.touched.organization_id &&
                    formik.errors.organization_id && (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.organization_id}
                      </div>
                    )}
                </div>
              )}

              {/* Date of Birth */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Date of Birth
                </label>
                <input
                  type="date"
                  name="dob"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Gender */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Gender
                </label>
                <div className="relative">
                  <select
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Gender</option>
                    {sortAlphabetically(genderDropDownOptions).map(
                      (opt, index) => (
                        <option key={index} value={opt.toLowerCase()}>
                          {opt}
                        </option>
                      )
                    )}
                  </select>
                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
              </div>
            </div>

            {/* About */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                About
              </label>
              <textarea
                name="about"
                placeholder="Tell us about yourself..."
                value={formik.values.about}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Address */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <textarea
                name="address"
                placeholder="Enter your address..."
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {formik.isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
