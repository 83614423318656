import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { ChevronDown } from "lucide-react";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import {
  getTicketTypeDetails,
  updateTicketTypeDetails,
} from "../../network/event_api";
import { getAllOrganizations } from "../../network/organization_api";
import { sortAlphabetically } from "../../utils/helper";

const TICKET_CATEGORIES = [
  { value: "vip", name: "VIP" },
  { value: "general", name: "General" },
  { value: "other", name: "Other" },
];

const EditTicketType = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [organizations, setOrganizations] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      ticket_category: "",
      organization_id: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Ticket type name is required";
      }

      if (!values.ticket_category) {
        errors.ticket_category = "Ticket category is required";
      }

      if (!values.organization_id) {
        errors.organization_id = "Organization is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const response = await updateTicketTypeDetails(id, values);
        if (response.code === 200) {
          toast.success("Ticket type updated successfully");
          setTimeout(() => {
            redirectToTicketTypes();
          }, 500);
        } else {
          toast.error("Failed to update ticket type");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  useEffect(() => {
    const initializeData = async () => {
      try {
        loader(true);
        // Fetch organizations first
        const orgResponse = await getAllOrganizations({
          is_active: true,
        });

        if (orgResponse.code === 200) {
          const alphabeticallySortedOrganizations = sortAlphabetically(
            orgResponse.result
          );
          setOrganizations(alphabeticallySortedOrganizations);

          // Then fetch ticket details
          const ticketResponse = await getTicketTypeDetails(id);
          if (ticketResponse.code === 200) {
            const ticketData = ticketResponse.result;

            // Check if organization exists in the list
            const organizationExists = orgResponse.result.some(
              (org) =>
                org.id.toString() === ticketData.organization_id.toString()
            );

            formik.setValues({
              name: ticketData.name,
              description: ticketData.description,
              ticket_category: ticketData.ticket_category,
              organization_id: organizationExists
                ? ticketData.organization_id
                : "",
            });
          } else {
            toast.error("Failed to fetch ticket type details");
            redirectToTicketTypes();
          }
        } else {
          toast.error("Failed to fetch organizations");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
        redirectToTicketTypes();
      } finally {
        loader(false);
      }
    };

    initializeData();
  }, [id]);

  const redirectToTicketTypes = () => {
    navigate(ROUTES.TICKET_TYPES.LIST);
  };

  const redirectBackToTicketType = () => {
    navigate(ROUTES.TICKET_TYPES.VIEW.replace(":id", id));
  };

  return (
    <div>
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Ticket Types</h1>
          <p className="text-sm text-gray-500">
            <span>Ticket Control - </span>
            <span
              onClick={redirectToTicketTypes}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Ticket Types
            </span>
            <span> / </span>
            <span
              onClick={redirectBackToTicketType}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              {id}
            </span>
            <span> / Edit Ticket Type</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">
            Edit Ticket Type
          </h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* Ticket Type Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter ticket type name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.name}
                  </div>
                )}
              </div>

              {/* Ticket Category */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Category <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <select
                    name="ticket_category"
                    value={formik.values.ticket_category}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Category</option>
                    {sortAlphabetically(TICKET_CATEGORIES).map((category) => (
                      <option key={category.value} value={category.value}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
                {formik.touched.ticket_category &&
                  formik.errors.ticket_category && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.ticket_category}
                    </div>
                  )}
              </div>

              {/* Organization */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Organization <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <select
                    name="organization_id"
                    value={formik.values.organization_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Organization</option>
                    {organizations.map((org) => (
                      <option key={org.id} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
                {formik.touched.organization_id &&
                  formik.errors.organization_id && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.organization_id}
                    </div>
                  )}
              </div>
            </div>

            {/* Description */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                name="description"
                placeholder="Enter ticket type description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {formik.isSubmitting ? "Updating..." : "Update Ticket Type"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTicketType;
