import axios from "axios";
import { KEY_LOGIN_RESULT } from "./constants";

// Set the base URL for your API
const baseUrl = "https://dev.wooziee.com/api";
//const baseUrl = "http://localhost:8888/api";
axios.defaults.baseURL = baseUrl;

// Create an Axios instance with the base URL
const axiosClient = axios.create();

// Add an interceptor to automatically include the authorization header
axiosClient.interceptors.request.use(
  (config) => {
    const storedLoginResult = JSON.parse(
      localStorage.getItem(KEY_LOGIN_RESULT)
    );
    const token = storedLoginResult ? storedLoginResult.token : null;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to handle API responses globally
const handleApiResponse = (response) => {
  // Check if we have a response with data
  if (response.data) {
    // Return the data for both 200
    if (response.data.code === 200) {
      return response.data;
    }
  }

  // Handle other error cases
  throw new Error(response.data ? response.data.message : "Error occurred");
};

const handleApiError = (error) => {
  if (error.response) {
    // If it's a 404 response, return the response data instead of throwing
    if (error.response.status === 404 || error.response.data?.code === 404) {
      return error.response.data;
    }

    // Handle other error responses
    const errorMessage = error.response.data?.message || "Error occurred";
    throw new Error(errorMessage);
  } else if (error.request) {
    // The request was made but no response was received
    throw new Error("No response received for the request");
  } else {
    // Something happened in setting up the request
    throw new Error("Error setting up the request: " + error.message);
  }
};

// Add an interceptor to handle API responses globally
axiosClient.interceptors.response.use(
  (response) => handleApiResponse(response),
  (error) => {
    console.error("Network Error:", error);
    return Promise.reject(handleApiError(error));
  }
);

export default axiosClient;
