import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { login, verifyOtp } from "../../network/auth_api";
import { KEY_LOGIN_RESULT } from "../../utils/constants";
import loader from "../../components/loader";
import ROUTES from "../../config/routes";

const Login = () => {
  const navigate = useNavigate();
  const [isOtpSent, setIsOtpSent] = useState(false);

  useEffect(() => {
    let loginResult = localStorage.getItem(KEY_LOGIN_RESULT);
    if (loginResult) {
      try {
        loginResult = JSON.parse(loginResult);
        if (loginResult && loginResult.token) {
          navigate(ROUTES.DASHBOARD);
        }
      } catch (error) {
        localStorage.removeItem(KEY_LOGIN_RESULT);
      }
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      mobile_number: "",
      otp: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.mobile_number) {
        errors.mobile_number = "Mobile number is required";
      } else if (!/^\d{10}$/.test(values.mobile_number)) {
        errors.mobile_number = "Mobile number must be exactly 10 digits";
      }
      if (isOtpSent && !values.otp) {
        errors.otp = "OTP is required";
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        loader(true);
        if (!isOtpSent) {
          // Send OTP
          const response = await login({
            mobile_number: values.mobile_number,
            platform: "web",
          });
          toast.success(response.message || "OTP sent successfully");
          setIsOtpSent(true);
        } else {
          // Verify OTP
          const response = await verifyOtp({
            mobile_number: values.mobile_number,
            otp: values.otp,
          });
          const loginResult = response.result;
          toast.success(response.message);
          localStorage.setItem(KEY_LOGIN_RESULT, JSON.stringify(loginResult));
          navigate(ROUTES.DASHBOARD);
        }
      } catch (error) {
        toast.error(error.message || "Operation failed");
      } finally {
        loader(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="min-h-screen bg-slate-50 flex">
      {/* Left side with illustration */}
      <div className="flex-1 flex items-center justify-center p-8">
        <div className="relative w-full max-w-md">
          <div className="mt-16">
            <img
              src="assets/img/login_bg.svg"
              alt="Login"
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>

      {/* Right side with login form */}
      <div className="flex-1 flex items-center justify-center p-8 bg-white">
        <div className="w-full max-w-md space-y-6">
          <div className="text-left space-y-2">
            <h1 className="text-2xl font-semibold text-gray-900">
              Welcome to Wooziee
            </h1>
            <p className="text-gray-600">Your Admin Dashboard</p>
          </div>

          <div className="space-y-4">
            {/* Social Login Buttons */}
            <div className="grid grid-cols-2 gap-4">
              <button
                type="button"
                className="flex items-center justify-center gap-2 px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors bg-white"
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                </svg>
                <span className="text-sm text-gray-600">
                  Sign in with Google
                </span>
              </button>
              <button
                type="button"
                className="flex items-center justify-center gap-2 px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors bg-white"
              >
                <svg
                  className="w-5 h-5 text-blue-600"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                </svg>
                <span className="text-sm text-gray-600">Sign in with FB</span>
              </button>
            </div>

            {/* Divider */}
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  or sign in with
                </span>
              </div>
            </div>

            {/* Login Form */}
            <form onSubmit={formik.handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="mobile_number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobile Number
                </label>
                <input
                  type="text"
                  id="mobile_number"
                  name="mobile_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile_number}
                  disabled={isOtpSent}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
                />
                {formik.touched.mobile_number &&
                  formik.errors.mobile_number && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.mobile_number}
                    </div>
                  )}
              </div>

              {isOtpSent && (
                <div>
                  <label
                    htmlFor="otp"
                    className="block text-sm font-medium text-gray-700"
                  >
                    OTP
                  </label>
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.otp}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  />
                  {formik.touched.otp && formik.errors.otp && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.otp}
                    </div>
                  )}
                </div>
              )}

              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {formik.isSubmitting
                  ? "Processing..."
                  : isOtpSent
                  ? "Verify OTP"
                  : "Send OTP"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
