import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  User,
  Mail,
  Phone,
  CheckCircle,
  XCircle,
  Pencil,
  Trash,
} from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getOrganizationDetails } from "../../network/organization_api";
import ROUTES from "../../config/routes";

const ViewOrganizationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [organizationResult, setOrganizationResult] = useState({});
  const [deletingOrganization, setDeletingOrganization] = useState(null);

  useEffect(() => {
    fetchOrganizationDetails();
  }, []);

  const handleDeleteClick = (organization) => {
    setDeletingOrganization(organization);
  };

  const handleCloseModal = () => {
    setDeletingOrganization(null);
  };

  const confirmDelete = async () => {};

  const redirectToOrganizations = () => {
    navigate(ROUTES.ORGANIZATIONS.LIST);
  };

  const openEditOrganization = (organization) => {
    navigate(ROUTES.ORGANIZATIONS.EDIT.replace(":id", organization.id));
  };

  const fetchOrganizationDetails = async () => {
    try {
      loader(true);
      const response = await getOrganizationDetails(id);
      setOrganizationResult(response.result);
      toast.success("Organization Detail fetched successfully");
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">
            Organizations
          </h1>
          <p className="text-sm text-gray-500">
            <span>Organization Control - </span>
            <span
              onClick={redirectToOrganizations}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Organizations
            </span>
            <span> / {id}</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-8">
            Organization details
          </h2>

          <div className="flex flex-row mb-4">
            <img
              className="w-24 h-24 rounded-full bg-gray-300"
              src={organizationResult.logo}
              alt="logo"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Name
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <User className="w-4 h-4 text-gray-500" />
                <span>{organizationResult.name}</span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Slug
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <User className="w-4 h-4 text-gray-500" />
                <span>{organizationResult.slug}</span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Email
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Mail className="w-4 h-4 text-gray-500" />
                <span>{organizationResult.email}</span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Mobile Number
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Phone className="w-4 h-4 text-gray-500" />
                <span>{organizationResult.mobile_number}</span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Organization Verified
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                {organizationResult.is_verified ? (
                  <CheckCircle className="w-4 h-4 text-green-600" />
                ) : (
                  <XCircle className="w-4 h-4 text-red-600" />
                )}
                <span>
                  {organizationResult.is_verified ? "Verified" : "Not Verified"}
                </span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-semibold leading-none text-gray-700">
                Status
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span
                  className={`px-2 py-1 text-xs font-medium rounded-full ${
                    organizationResult.is_active
                      ? "text-green-600 bg-green-100"
                      : "text-red-600 bg-red-100"
                  }`}
                >
                  {organizationResult.is_active ? "Active" : "Inactive"}
                </span>
              </p>
            </div>
          </div>

          <div className="flex flex-row space-x-4 mt-8">
            <button
              onClick={() => openEditOrganization(organizationResult)}
              type="button"
              className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Pencil className="w-5 h-5 mr-1.5 -ml-1" />
              Edit
            </button>
            <button
              onClick={() => handleDeleteClick(organizationResult)}
              type="button"
              className="inline-flex items-center text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Trash className="w-5 h-5 mr-1.5 -ml-1" />
              Delete
            </button>
          </div>
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this organization?"}
        isOpen={deletingOrganization}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ViewOrganizationDetails;
