import axiosClient from "../utils/api_client";

export function getAllOrganizations(param) {
  return axiosClient.get("/admin/organizations", {
    params: param,
  });
}

export function getOrganizationDetails(id) {
  return axiosClient.get(`/organizations/${id}`);
}

export function addOrganization(data) {
  return axiosClient.post("/admin/organizations", data);
}

export function updateOrganizationDetails(id, data) {
  return axiosClient.put(`/admin/organizations/${id}`, data);
}
