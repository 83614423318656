import React from "react";
import { Trash2 } from "lucide-react";

const FilterActionsFooter = ({
  onReset = () => {},
  applyText = "Apply",
  resetText = "Reset",
  className = "",
}) => {
  return (
    <div className={`fixed bottom-0 left-0 w-full bg-white ${className}`}>
      <div className="border-t border-gray-200 w-full" />
      <div className="flex justify-center w-full pb-4 space-x-4 px-4 pt-4">
        <button
          type="submit"
          className="w-full justify-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          {applyText}
        </button>
        <button
          type="button"
          onClick={onReset}
          className="w-full justify-center text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          <Trash2 className="w-5 h-5 mr-1 -ml-1" />
          {resetText}
        </button>
      </div>
    </div>
  );
};

export default FilterActionsFooter;
