import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronDown } from "lucide-react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import {
  getAllGenres,
  getArtistDetails,
  updateArtistDetails,
} from "../../network/event_api";
import DateFormatter from "../../utils/date_formatter";
import { sortAlphabetically } from "../../utils/helper";
import MultiselectDropdown from "../../components/multi_select_dropdown";

const EditArtist = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    fetchGenres();
  }, []);

  const fetchGenres = async () => {
    try {
      const response = await getAllGenres();
      if (response.code === 200) {
        const alphabeticallySortedGenres = sortAlphabetically(response.result);
        setGenres(alphabeticallySortedGenres);
      }
    } catch (error) {
      toast.error("Failed to fetch genres");
    }
  };

  useEffect(() => {
    fetchArtistData();
  }, [id]);

  const fetchArtistData = async () => {
    try {
      loader(true);
      const response = await getArtistDetails(id);
      if (response.code === 200) {
        const artistData = response.result;
        formik.setValues({
          name: artistData.name || "",
          bio: artistData.bio || "",
          date_of_birth:
            DateFormatter.formatYYYYMMDD(artistData.date_of_birth) || "",
          country: artistData.country || "",
          genre_id: artistData.genres?.map((genre) => genre.id) || [],
          artist_genre_id:
            artistData.genres && artistData.genres.length > 0
              ? artistData.genres[0].artist_genre_id
              : "",
          is_active: artistData.is_active,
        });
      } else {
        toast.error("Failed to fetch artist data");
        redirectToArtists();
      }
    } catch (error) {
      toast.error("Failed to fetch artist data");
      redirectToArtists();
    } finally {
      loader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      bio: "",
      date_of_birth: "",
      country: "",
      genre_id: [], // Using an Array to store mutiple `genre_id` values
      artist_genre_id: "",
      is_active: 1,
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Name is required";
      }

      if (values.name && values.name.length < 2) {
        errors.name = "Name must be at least 2 characters long";
      }

      if (!values.genre_id.length > 0) {
        errors.genre_id = "Genre is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const formData = {
          ...values,
          date_of_birth: values.date_of_birth || null,
        };
        const response = await updateArtistDetails(id, formData);
        if (response.code === 200) {
          toast.success("Artist updated successfully");
          setTimeout(() => {
            redirectToArtists();
          }, 500);
        } else {
          toast.error("Failed to update artist");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  const redirectToArtists = () => {
    navigate(ROUTES.ARTISTS.LIST);
  };

  const redirectBackToArtist = () => {
    navigate(ROUTES.ARTISTS.VIEW.replace(":id", id));
  };

  return (
    <div>
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Artists</h1>
          <p className="text-sm text-gray-500">
            <span>Masters - </span>
            <span
              onClick={redirectToArtists}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Artists
            </span>
            <span> / </span>
            <span
              onClick={redirectBackToArtist}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              {id}
            </span>
            <span> / </span>
            <span> Edit Artist</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">
            Edit Artist
          </h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter artist name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.name}
                  </div>
                )}
              </div>

              {/* Date of Birth */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="date_of_birth"
                  name="date_of_birth"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date_of_birth}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Country */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Country
                </label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  placeholder="Enter country"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Active Status */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <div className="relative">
                  <select
                    id="is_active"
                    name="is_active"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.is_active}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
              </div>

              {/* Genre */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Genre <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <MultiselectDropdown
                    formik={formik}
                    options={genres}
                    formikValue="genre_id"
                    placeholder="Select Genre"
                  />

                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
                {formik.touched.genre_id && formik.errors.genre_id && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.genre_id}
                  </div>
                )}
              </div>
            </div>

            {/* Bio */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Bio
              </label>
              <textarea
                id="bio"
                name="bio"
                placeholder="Enter artist bio..."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bio}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {formik.isSubmitting ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditArtist;
