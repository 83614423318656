export function sortAlphabetically(param, key = "name") {
  if (!Array.isArray(param)) {
    throw new Error("Param in sortAlphabetically function must be an array");
  }

  if (param.length === 0) return param; // Return the array as it is if it's empty

  return param.sort((a, b) => {
    let nameA, nameB;

    // Check if the first element of `param` is an object and has a 'name' property
    if (typeof param[0] === "object" && key in param[0]) {
      nameA = a[key].toUpperCase();
      nameB = b[key].toUpperCase();
    } else {
      nameA = String(a).toUpperCase();
      nameB = String(b).toUpperCase();
    }

    if (nameA < nameB) return -1; // Move "a" before "b"
    if (nameA > nameB) return 1; // Move "a" after "b"
    return 0; // order remains same
  });
}

export const methodModel = {
  find(optionsList, value, key) {
    return optionsList.find((item) => item[key] === value);
  },
};
