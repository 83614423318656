import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  Search,
  Download,
  ChevronDown,
  MoreVertical,
  Pencil,
  Trash2,
} from "lucide-react";

const AdminDashboard = () => {
  return (
    <div>
      <h1>Admin Dashboard</h1>
    </div>
  );
};

export default AdminDashboard;
