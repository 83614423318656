import React, { useEffect, useState } from "react";
import { Plus, Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import {
  addEvent,
  getAllEventVenues,
  getAllEventCategories,
  getAllTicketType,
} from "../../network/event_api";
import { getAllOrganizations } from "../../network/organization_api";

const AddEvent = () => {
  const navigate = useNavigate();
  const [venues, setVenues] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = async () => {
    try {
      loader(true);
      const [venuesRes, orgsRes, categoriesRes, ticketTypesRes] =
        await Promise.all([
          getAllEventVenues(),
          getAllOrganizations(),
          getAllEventCategories(),
          getAllTicketType(),
        ]);

      if (venuesRes.code === 200) setVenues(venuesRes.result);
      if (orgsRes.code === 200) setOrganizations(orgsRes.result);
      if (categoriesRes.code === 200) setCategories(categoriesRes.result);
      if (ticketTypesRes.code === 200) setTicketTypes(ticketTypesRes.result);
    } catch (error) {
      toast.error("Failed to fetch required data");
    } finally {
      loader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      published_date: "",
      ticket_live_start_date: "",
      ticket_live_end_date: "",
      event_start_date: "",
      event_end_date: "",
      event_venue_id: "",
      organization_id: "",
      event_category_id: "",
      event_ticket_pricings: [],
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.published_date) {
        errors.published_date = "Published date is required";
      }
      if (!values.ticket_live_start_date) {
        errors.ticket_live_start_date = "Ticket start date is required";
      }
      if (!values.ticket_live_end_date) {
        errors.ticket_live_end_date = "Ticket end date is required";
      }
      if (!values.event_start_date) {
        errors.event_start_date = "Event start date is required";
      }
      if (!values.event_end_date) {
        errors.event_end_date = "Event end date is required";
      }
      if (!values.event_venue_id) {
        errors.event_venue_id = "Venue is required";
      }
      if (!values.organization_id) {
        errors.organization_id = "Organization is required";
      }
      if (!values.event_category_id) {
        errors.event_category_id = "Category is required";
      }

      // Date validation
      if (values.ticket_live_end_date < values.ticket_live_start_date) {
        errors.ticket_live_end_date =
          "Ticket end date must be after start date";
      }
      if (values.event_end_date < values.event_start_date) {
        errors.event_end_date = "Event end date must be after start date";
      }

      // Ticket pricing validation
      const ticketErrors = [];
      values.event_ticket_pricings.forEach((pricing, index) => {
        const pricingError = {};
        if (!pricing.ticket_price || pricing.ticket_price <= 0) {
          pricingError.ticket_price = "Valid price is required";
        }
        if (
          !pricing.ticket_total_quantity ||
          pricing.ticket_total_quantity <= 0
        ) {
          pricingError.ticket_total_quantity = "Valid quantity is required";
        }
        if (Object.keys(pricingError).length > 0) {
          ticketErrors[index] = pricingError;
        }
      });

      if (ticketErrors.length > 0) {
        errors.event_ticket_pricings = ticketErrors;
      }

      if (values.event_ticket_pricings.length === 0) {
        errors.event_ticket_pricings = "At least one ticket type is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const response = await addEvent(values);
        if (response.code === 200) {
          toast.success("Event added successfully");
          setTimeout(() => {
            redirectToEvents();
          }, 500);
        } else {
          toast.error("Failed to add event");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  const handleAddTicketType = () => {
    const firstAvailableType = ticketTypes.find(
      (type) =>
        !formik.values.event_ticket_pricings.some(
          (pricing) => pricing.ticket_type_id === type.id
        )
    );

    if (firstAvailableType) {
      formik.setFieldValue("event_ticket_pricings", [
        ...formik.values.event_ticket_pricings,
        {
          ticket_type_id: firstAvailableType.id,
          ticket_price: "",
          ticket_total_quantity: "",
        },
      ]);
    } else {
      toast.warning("All ticket types have been added");
    }
  };

  const handleRemoveTicketType = (index) => {
    const newPricings = [...formik.values.event_ticket_pricings];
    newPricings.splice(index, 1);
    formik.setFieldValue("event_ticket_pricings", newPricings);
  };

  const redirectToEvents = () => {
    navigate(ROUTES.EVENTS.LIST);
  };

  const getTicketTypeName = (typeId) => {
    const ticketType = ticketTypes.find((type) => type.id === typeId);
    return ticketType ? ticketType.name : "";
  };

  return (
    <div>
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Events</h1>
          <p className="text-sm text-gray-500">
            <span>Event Control - </span>
            <span
              onClick={redirectToEvents}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Events
            </span>
            <span> / Add Event</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">
            Add Event
          </h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter event name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.name}
                  </div>
                )}
              </div>

              {/* Published Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Published Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="published_date"
                  name="published_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.published_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.published_date &&
                  formik.errors.published_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.published_date}
                    </div>
                  )}
              </div>

              {/* Ticket Live Start Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Ticket Start Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="ticket_live_start_date"
                  name="ticket_live_start_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ticket_live_start_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.ticket_live_start_date &&
                  formik.errors.ticket_live_start_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.ticket_live_start_date}
                    </div>
                  )}
              </div>

              {/* Ticket Live End Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Ticket End Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="ticket_live_end_date"
                  name="ticket_live_end_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ticket_live_end_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.ticket_live_end_date &&
                  formik.errors.ticket_live_end_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.ticket_live_end_date}
                    </div>
                  )}
              </div>

              {/* Event Start Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Event Start Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="event_start_date"
                  name="event_start_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.event_start_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.event_start_date &&
                  formik.errors.event_start_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_start_date}
                    </div>
                  )}
              </div>

              {/* Event End Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Event End Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="event_end_date"
                  name="event_end_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.event_end_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.event_end_date &&
                  formik.errors.event_end_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_end_date}
                    </div>
                  )}
              </div>

              {/* Venue Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Venue <span className="text-red-500">*</span>
                </label>
                <select
                  id="event_venue_id"
                  name="event_venue_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.event_venue_id}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Venue</option>
                  {venues.map((venue) => (
                    <option key={venue.id} value={venue.id}>
                      {venue.name}
                    </option>
                  ))}
                </select>
                {formik.touched.event_venue_id &&
                  formik.errors.event_venue_id && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_venue_id}
                    </div>
                  )}
              </div>

              {/* Organization Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Organization <span className="text-red-500">*</span>
                </label>
                <select
                  id="organization_id"
                  name="organization_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.organization_id}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Organization</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
                {formik.touched.organization_id &&
                  formik.errors.organization_id && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.organization_id}
                    </div>
                  )}
              </div>

              {/* Category Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Category <span className="text-red-500">*</span>
                </label>
                <select
                  id="event_category_id"
                  name="event_category_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.event_category_id}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                {formik.touched.event_category_id &&
                  formik.errors.event_category_id && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_category_id}
                    </div>
                  )}
              </div>
            </div>

            {/* Description */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                placeholder="Enter event description..."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Ticket Pricing Section */}
            <div className="mt-8">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-700">
                  Ticket Pricing
                </h3>
                <button
                  type="button"
                  onClick={handleAddTicketType}
                  className="px-4 py-2 text-sm bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center gap-2"
                >
                  <Plus size={16} />
                  Add Ticket Type
                </button>
              </div>

              {typeof formik.errors.event_ticket_pricings === "string" && (
                <div className="text-red-500 text-sm mb-4">
                  {formik.errors.event_ticket_pricings}
                </div>
              )}

              {formik.values.event_ticket_pricings.map((pricing, index) => (
                <div
                  key={index}
                  className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6 p-6 border rounded-lg bg-gray-50"
                >
                  {/* Ticket Type */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Ticket Type
                    </label>
                    <select
                      value={pricing.ticket_type_id}
                      onChange={(e) => {
                        const newPricings = [
                          ...formik.values.event_ticket_pricings,
                        ];
                        newPricings[index].ticket_type_id = Number(
                          e.target.value
                        );
                        formik.setFieldValue(
                          "event_ticket_pricings",
                          newPricings
                        );
                      }}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                    >
                      {ticketTypes
                        .filter(
                          (type) =>
                            type.id === pricing.ticket_type_id ||
                            !formik.values.event_ticket_pricings.some(
                              (p) => p.ticket_type_id === type.id
                            )
                        )
                        .map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Price
                    </label>
                    <input
                      type="number"
                      value={pricing.ticket_price}
                      onChange={(e) => {
                        const newPricings = [
                          ...formik.values.event_ticket_pricings,
                        ];
                        newPricings[index].ticket_price = Number(
                          e.target.value
                        );
                        formik.setFieldValue(
                          "event_ticket_pricings",
                          newPricings
                        );
                      }}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                      placeholder="Enter price"
                    />
                    {formik.errors.event_ticket_pricings?.[index]
                      ?.ticket_price && (
                      <div className="text-red-500 text-sm mt-1">
                        {
                          formik.errors.event_ticket_pricings[index]
                            .ticket_price
                        }
                      </div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Quantity
                    </label>
                    <input
                      type="number"
                      value={pricing.ticket_total_quantity}
                      onChange={(e) => {
                        const newPricings = [
                          ...formik.values.event_ticket_pricings,
                        ];
                        newPricings[index].ticket_total_quantity = Number(
                          e.target.value
                        );
                        formik.setFieldValue(
                          "event_ticket_pricings",
                          newPricings
                        );
                      }}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                      placeholder="Enter quantity"
                    />
                    {formik.errors.event_ticket_pricings?.[index]
                      ?.ticket_total_quantity && (
                      <div className="text-red-500 text-sm mt-1">
                        {
                          formik.errors.event_ticket_pricings[index]
                            .ticket_total_quantity
                        }
                      </div>
                    )}
                  </div>

                  <div className="flex">
                    <button
                      type="button"
                      onClick={() => handleRemoveTicketType(index)}
                      className="px-4 py-2 text-sm bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 flex items-center gap-2 h-10 mt-8"
                    >
                      <Trash2 size={16} />
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {formik.isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
