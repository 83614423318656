import React from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "../config/routes";

const NotFound = () => {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="text-center">
        {/* 404 Illustration */}
        <div className="w-64 h-64 mx-auto mb-8">
          <img
            src="/assets/img/not_found.svg"
            alt="404 Not Found"
            className="w-full h-full object-contain"
          />
        </div>

        {/* Text Content */}
        <h1 className="mb-4 text-4xl font-bold text-gray-800">Opps!!!</h1>
        <p className="mb-8 text-lg text-gray-600">
          This page you are looking for could not be found.
        </p>

        {/* Button */}
        <button
          onClick={handleGoHome}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          Go Back to Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
