import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Music, Pencil, Trash } from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getGenreDetails } from "../../network/event_api";
import ROUTES from "../../config/routes";
import ArtistDisplay from "../../components/artist_display";

const ViewGenreDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [genreResult, setGenreResult] = useState({});
  const [deletingGenre, setDeletingGenre] = useState(null);

  useEffect(() => {
    fetchGenreDetails();
  }, []);

  const handleDeleteClick = (user) => {
    setDeletingGenre(user);
  };

  const handleCloseModal = () => {
    setDeletingGenre(null);
  };

  const confirmDelete = async () => {};

  const redirectToGenres = () => {
    navigate(ROUTES.GENRES.LIST);
  };

  const editGenreDetails = (genre) => {
    navigate(ROUTES.GENRES.EDIT.replace(":id", genre.id));
  };

  const fetchGenreDetails = async () => {
    try {
      loader(true);
      const response = await getGenreDetails(id);
      setGenreResult(response.result);
      toast.success("Genre Detail fetched successfully");
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Genres</h1>
          <p className="text-sm text-gray-500">
            <span>Masters - </span>
            <span
              onClick={redirectToGenres}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Genres
            </span>
            <span> / {id}</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-8">
            Genre details
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="md:col-span-2 w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Name
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span>{genreResult.name || "--"}</span>
              </p>
            </div>

            <div className="md:col-span-2 w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Description
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span>
                  {genreResult.description || "No description available"}
                </span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Genre
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                {/*<Music className="w-4 h-4 text-gray-500" />
                                {genreResult.genres?.length > 0 ? <ArtistDisplay genres={genreResult.genres.map(genre => genre.name).join(', ')

                                } /> : <span className="text-gray-400">--</span>}
                                */}
              </p>
            </div>
          </div>

          <div className="flex flex-row space-x-4 mt-8">
            <button
              onClick={() => editGenreDetails(genreResult)}
              type="button"
              className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Pencil className="w-5 h-5 mr-1.5 -ml-1" />
              Edit
            </button>
            <button
              onClick={() => handleDeleteClick(genreResult)}
              type="button"
              className="inline-flex items-center text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Trash className="w-5 h-5 mr-1.5 -ml-1" />
              Delete
            </button>
          </div>
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this genre?"}
        isOpen={deletingGenre}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ViewGenreDetails;
