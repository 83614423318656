import axiosClient from "../utils/api_client";

export function getAllUsers(param) {
  return axiosClient.get("/admin/users", {
    params: param,
  });
}

export function getUserDetails(id) {
  return axiosClient.get(`/admin/users/${id}`);
}

export function addUser(data) {
  return axiosClient.post("/admin/users", data);
}

export function updateUserDetails(id, data) {
  return axiosClient.put(`/users/${id}`, data);
}

export function getOrganizationUsers(id) {
  return axiosClient.get(`/admin/organizations/${id}/users`);
}
