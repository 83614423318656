import React, { useEffect, useState } from "react";
import {
  LayoutDashboard,
  Ticket,
  Calendar,
  Tags,
  MapPin,
  Users,
  Music,
  ListMusic,
  BellRing,
  Building,
} from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import ROUTES from "../config/routes";

const SideNav = ({ isSidebarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeRoute, setActiveRoute] = useState("/");

  const handleNavClick = (route) => {
    setActiveRoute(route);
    navigate(route);
  };

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  return (
    <div
      className={`bg-white w-64 border-r flex-shrink-0 h-screen flex flex-col ${
        isSidebarOpen ? "" : "hidden"
      }`}
    >
      {/* Logo - Fixed at top */}
      <div className="px-6 py-4 border-b">
        <div className="flex items-center space-x-3">
          <img
            src="/api/placeholder/40/40"
            alt="Wooziee Logo"
            className="h-10 w-10 rounded-lg"
          />
          <span className="text-xl font-semibold">WOOZIEE</span>
        </div>
      </div>

      {/* Navigation - Scrollable */}
      <nav className="flex-1 overflow-y-auto p-4 space-y-6">
        {/* Dashboard */}
        <div className="space-y-2">
          <NavItem
            icon={<LayoutDashboard className="w-5 h-5 text-gray-400" />}
            label="Dashboard"
            routeTo={ROUTES.DASHBOARD}
            isActive={activeRoute === ROUTES.DASHBOARD}
            onClick={() => handleNavClick(ROUTES.DASHBOARD)}
          />
        </div>

        {/* Masters Section */}
        <div className="space-y-4">
          <div className="text-sm font-medium text-gray-400 px-2">MASTERS</div>
          <div className="space-y-3">
            <NavItem
              icon={<Users className="w-5 h-5 text-gray-400" />}
              routeTo={ROUTES.USERS.LIST}
              label="Users"
              isActive={activeRoute === ROUTES.USERS.LIST}
              onClick={() => handleNavClick(ROUTES.USERS.LIST)}
            />
            <NavItem
              icon={<Music className="w-5 h-5 text-gray-400" />}
              label="Artists"
              routeTo={ROUTES.ARTISTS.LIST}
              isActive={activeRoute === ROUTES.ARTISTS.LIST}
              onClick={() => handleNavClick(ROUTES.ARTISTS.LIST)}
            />
            <NavItem
              icon={<ListMusic className="w-5 h-5 text-gray-400" />}
              label="Genres"
              routeTo={ROUTES.GENRES.LIST}
              isActive={activeRoute === ROUTES.GENRES.LIST}
              onClick={() => handleNavClick(ROUTES.GENRES.LIST)}
            />
          </div>
        </div>

        {/* Event Control Section */}
        <div className="space-y-4">
          <div className="text-sm font-medium text-gray-400 px-2">
            EVENT CONTROL
          </div>
          <div className="space-y-3">
            <NavItem
              icon={<Calendar className="w-5 h-5 text-gray-400" />}
              label="Events"
              routeTo={ROUTES.EVENTS.LIST}
              isActive={activeRoute === ROUTES.EVENTS.LIST}
              onClick={() => handleNavClick(ROUTES.EVENTS.LIST)}
            />
            <NavItem
              icon={<Tags className="w-5 h-5 text-gray-400" />}
              label="Event Categories"
              routeTo={ROUTES.EVENTS.CATEGORIES.LIST}
              isActive={activeRoute === ROUTES.EVENTS.CATEGORIES.LIST}
              onClick={() => handleNavClick(ROUTES.EVENTS.CATEGORIES.LIST)}
            />
            <NavItem
              icon={<MapPin className="w-5 h-5 text-gray-400" />}
              label="Event Venues"
              routeTo={ROUTES.EVENTS.VENUES.LIST}
              isActive={activeRoute === ROUTES.EVENTS.VENUES.LIST}
              onClick={() => handleNavClick(ROUTES.EVENTS.VENUES.LIST)}
            />
          </div>
        </div>

        {/* Organization Control Section */}
        <div className="space-y-4">
          <div className="text-sm font-medium text-gray-400 px-2">
            ORGANIZATION CONTROL
          </div>
          <div className="space-y-3">
            <NavItem
              icon={<Building className="w-5 h-5 text-gray-400" />}
              label="Organizations"
              routeTo={ROUTES.ORGANIZATIONS.LIST}
              isActive={activeRoute === ROUTES.ORGANIZATIONS.LIST}
              onClick={() => handleNavClick(ROUTES.ORGANIZATIONS.LIST)}
            />
            {/*<NavItem
                            icon={<FileCheck className="w-5 h-5 text-gray-400" />}
                            label="Contracts"
                            routeTo={ROUTES.ORGANIZATIONS.CONTRACTS.LIST}
                            isActive={activeRoute === ROUTES.ORGANIZATIONS.CONTRACTS.LIST}
                            onClick={() => handleNavClick(ROUTES.ORGANIZATIONS.CONTRACTS.LIST)}
                        />*/}
          </div>
        </div>

        {/* Ticket Control Section */}
        <div className="space-y-4">
          <div className="text-sm font-medium text-gray-400 px-2">
            TICKET CONTROL
          </div>
          <div className="space-y-3">
            <NavItem
              icon={<Ticket className="w-5 h-5 text-gray-400" />}
              label="Ticket Type"
              routeTo={ROUTES.TICKET_TYPES.LIST}
              isActive={activeRoute === ROUTES.TICKET_TYPES.LIST}
              onClick={() => handleNavClick(ROUTES.TICKET_TYPES.LIST)}
            />
          </div>
        </div>

        {/* Alert Section */}
        <div className="space-y-4">
          <div className="text-sm font-medium text-gray-400 px-2">
            ALERTS & NOTIFICATIONS
          </div>
          <div className="space-y-3">
            <NavItem
              icon={<BellRing className="w-5 h-5 text-gray-400" />}
              label="Notifications"
              routeTo={ROUTES.NOTIFICATIONS.LIST}
              isActive={activeRoute === ROUTES.NOTIFICATIONS.LIST}
              onClick={() => handleNavClick(ROUTES.NOTIFICATIONS.LIST)}
            />
          </div>
        </div>
      </nav>
    </div>
  );
};

const NavItem = ({ icon, label, isActive, routeTo, onClick }) => (
  <a
    href={routeTo}
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    className={`flex items-center space-x-3 px-2 py-2 rounded-lg ${
      isActive ? "bg-blue-50 text-blue-600" : "text-gray-600 hover:bg-gray-50"
    }`}
  >
    {icon}
    <span className="text-sm font-medium">{label}</span>
  </a>
);

export default SideNav;
