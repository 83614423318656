import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Pencil, Trash } from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getEventCategoryDetails } from "../../network/event_api";
import ROUTES from "../../config/routes";

const ViewEventCategoryDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventCategoryResult, setEventCategoryResult] = useState({});
  const [deletingEventCategory, setDeletingEventCategory] = useState(null);

  useEffect(() => {
    fetchEventCategoryDetails();
  }, []);

  const handleDeleteClick = (cat) => {
    setDeletingEventCategory(cat);
  };

  const handleCloseModal = () => {
    setDeletingEventCategory(null);
  };

  const confirmDelete = async () => {};

  const redirectToEventCategories = () => {
    navigate(ROUTES.EVENTS.CATEGORIES.LIST);
  };

  const editEventCategoryDetails = (cat) => {
    navigate(ROUTES.EVENTS.CATEGORIES.EDIT.replace(":id", cat.id));
  };

  const fetchEventCategoryDetails = async () => {
    try {
      loader(true);
      const response = await getEventCategoryDetails(id);
      setEventCategoryResult(response.result);
      toast.success("Event Category Detail fetched successfully");
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">
            Event Categories
          </h1>
          <p className="text-sm text-gray-500">
            <span>Event Control - </span>
            <span
              onClick={redirectToEventCategories}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Event Categories
            </span>
            <span> / {id}</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-8">
            Event Category details
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="md:col-span-2 w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Name
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span>{eventCategoryResult.name || "--"}</span>
              </p>
            </div>

            <div className="md:col-span-2 w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Description
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span>
                  {eventCategoryResult.description ||
                    "No description available"}
                </span>
              </p>
            </div>
          </div>

          <div className="flex flex-row space-x-4 mt-8">
            <button
              onClick={() => editEventCategoryDetails(eventCategoryResult)}
              type="button"
              className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Pencil className="w-5 h-5 mr-1.5 -ml-1" />
              Edit
            </button>
            <button
              onClick={() => handleDeleteClick(eventCategoryResult)}
              type="button"
              className="inline-flex items-center text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Trash className="w-5 h-5 mr-1.5 -ml-1" />
              Delete
            </button>
          </div>
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this event category?"}
        isOpen={deletingEventCategory}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ViewEventCategoryDetails;
