import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  User,
  CalendarDays,
  Star,
  MapPin,
  Pencil,
  Trash,
  ListMusic,
} from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getArtistDetails } from "../../network/event_api";
import ROUTES from "../../config/routes";
import DateFormatter from "../../utils/date_formatter";
import GenreDisplay from "../../components/genre_display";

const ViewUserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [artistResult, setArtistResult] = useState({});
  const [deletingArtist, setDeletingArtist] = useState(null);

  useEffect(() => {
    fetchArtistDetails();
  }, []);

  const handleDeleteClick = (user) => {
    setDeletingArtist(user);
  };

  const handleCloseModal = () => {
    setDeletingArtist(null);
  };

  const confirmDelete = async () => {};

  const redirectToArtists = () => {
    navigate(ROUTES.ARTISTS.LIST);
  };

  const editArtistDetails = (artist) => {
    navigate(ROUTES.ARTISTS.EDIT.replace(":id", artist.id));
  };

  const fetchArtistDetails = async () => {
    try {
      loader(true);
      const response = await getArtistDetails(id);
      setArtistResult(response.result);
      toast.success("Artist Detail fetched successfully");
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Artists</h1>
          <p className="text-sm text-gray-500">
            <span>Masters - </span>
            <span
              onClick={redirectToArtists}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Artists
            </span>
            <span> / {id}</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-8">
            Artist details
          </h2>

          <div className="flex flex-row mb-4">
            <img
              className="w-24 h-24 rounded-full bg-gray-300"
              src={artistResult.profile_image_url || "/assets/img/avatar.png"}
              alt="user"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Name
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <User className="w-4 h-4 text-gray-500" />
                <span>{artistResult.name}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Date of Birth
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <CalendarDays className="w-4 h-4 text-gray-500" />
                <span>
                  {DateFormatter.formatFullDate(artistResult.date_of_birth) ||
                    "--"}
                </span>
              </p>
            </div>

            <div className="md:col-span-2 w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Bio
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span>{artistResult.bio || "No biography available"}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Country
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <MapPin className="w-4 h-4 text-gray-500" />
                <span>{artistResult.country || "--"}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Popularity Score
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Star className="w-4 h-4 text-gray-500" />
                <span>{artistResult.popularity_score || "--"}</span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Genre
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <ListMusic className="w-4 h-4 text-gray-500" />
                {artistResult.genres?.length > 0 ? (
                  <GenreDisplay
                    genres={artistResult.genres
                      .map((genre) => genre.name)
                      .join(", ")}
                  />
                ) : (
                  <span className="text-gray-400">--</span>
                )}
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-semibold leading-none text-gray-700">
                Status
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span
                  className={`px-2 py-1 text-xs font-medium rounded-full ${
                    artistResult.is_active
                      ? "text-green-600 bg-green-100"
                      : "text-red-600 bg-red-100"
                  }`}
                >
                  {artistResult.is_active ? "Active" : "Inactive"}
                </span>
              </p>
            </div>
          </div>

          <div className="flex flex-row space-x-4 mt-8">
            <button
              onClick={() => editArtistDetails(artistResult)}
              type="button"
              className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Pencil className="w-5 h-5 mr-1.5 -ml-1" />
              Edit
            </button>
            <button
              onClick={() => handleDeleteClick(artistResult)}
              type="button"
              className="inline-flex items-center text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Trash className="w-5 h-5 mr-1.5 -ml-1" />
              Delete
            </button>
          </div>
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this artist?"}
        isOpen={deletingArtist}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ViewUserDetails;
