import React from "react";

const GenreDisplay = ({ genres }) => {
  // If genres is undefined, null, or empty string, show placeholder
  if (!genres) {
    return <span className="text-gray-400">--</span>;
  }

  // Convert string to array if it's a comma-separated string
  const genreArray =
    typeof genres === "string"
      ? genres
          .split(",")
          .map((g) => g.trim())
          .filter(Boolean)
      : Array.isArray(genres)
      ? genres
      : [];

  if (genreArray.length === 0) {
    return <span className="text-gray-400">--</span>;
  }

  return (
    <div className="flex flex-wrap gap-1">
      {genreArray.map((genre, index) => (
        <span
          key={index}
          className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
        >
          {genre}
        </span>
      ))}
    </div>
  );
};

export default GenreDisplay;
