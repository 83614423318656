import React from "react";
import NavBar from "./nav_bar";

const MainContent = ({
  children,
  isSidebarOpen,
  setIsSidebarOpen,
  loginResult,
}) => {
  return (
    <div className="flex-1 flex flex-col">
      {/* Header */}
      <NavBar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        loginResult={loginResult}
      />
      {/* Main Content Area */}
      <main className="flex-1 overflow-auto p-6">{children}</main>
    </div>
  );
};

export default MainContent;
