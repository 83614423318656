import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import {
  getOrganizationDetails,
  updateOrganizationDetails,
} from "../../network/organization_api";
import RadioGroup from "../../components/radio_group";

const EditOrganization = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchOrganizationDetails();
  }, []);

  const fetchOrganizationDetails = async () => {
    try {
      loader(true);
      const response = await getOrganizationDetails(id);
      if (response.code === 200) {
        const orgData = response.result;
        formik.setValues({
          name: orgData.name,
          mobile_number: orgData.mobile_number,
          email: orgData.email,
          address: orgData.address,
          is_active: orgData.is_active ? "1" : "0",
          is_verified: orgData.is_verified ? "1" : "0",
        });
      } else {
        toast.error("Failed to fetch organization details");
        redirectToOrganizations();
      }
    } catch (error) {
      toast.error("Failed to fetch organization details");
      redirectToOrganizations();
    } finally {
      loader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile_number: "",
      email: "",
      address: "",
      is_active: "1",
      is_verified: "1",
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Organization name is required";
      }

      if (!values.mobile_number) {
        errors.mobile_number = "Mobile number is required";
      } else if (!/^\d{10}$/.test(values.mobile_number)) {
        errors.mobile_number = "Please enter a valid 10-digit mobile number";
      }

      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const response = await updateOrganizationDetails(id, {
          ...values,
          is_active: values.is_active === "1",
          is_verified: values.is_verified === "1",
        });
        if (response.code === 200) {
          toast.success("Organization updated successfully");
          setTimeout(() => {
            redirectToOrganizations();
          }, 500);
        } else {
          toast.error("Failed to update organization");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  const redirectToOrganizations = () => {
    navigate(ROUTES.ORGANIZATIONS.LIST);
  };

  const redirectBackToOrganization = () => {
    navigate(ROUTES.ORGANIZATIONS.VIEW.replace(":id", id));
  };

  return (
    <div>
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">
            Organizations
          </h1>
          <p className="text-sm text-gray-500">
            <span>Organization Control - </span>
            <span
              onClick={redirectToOrganizations}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Organizations
            </span>
            <span> / </span>
            <span
              onClick={redirectBackToOrganization}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              {id}
            </span>
            <span> / Edit Organization</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">
            Edit Organization
          </h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* Organization Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Organization Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter organization name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.name}
                  </div>
                )}
              </div>

              {/* Mobile Number */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Mobile Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="mobile_number"
                  placeholder="Enter mobile number"
                  value={formik.values.mobile_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.mobile_number &&
                  formik.errors.mobile_number && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.mobile_number}
                    </div>
                  )}
              </div>

              {/* Email */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.email}
                  </div>
                )}
              </div>

              <div className="grid grid-cols-2 gap-6">
                <RadioGroup
                  label="Status"
                  name="is_active"
                  options={[
                    { value: "1", label: "Active" },
                    { value: "0", label: "Inactive" },
                  ]}
                  formik={formik}
                />

                <RadioGroup
                  label="Verification Status"
                  name="is_verified"
                  options={[
                    { value: "1", label: "Verified" },
                    { value: "0", label: "Not Verified" },
                  ]}
                  formik={formik}
                />
              </div>
            </div>

            {/* Address */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <textarea
                name="address"
                placeholder="Enter organization address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {formik.isSubmitting ? "Updating..." : "Update Organization"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditOrganization;
