import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Search,
  Download,
  ChevronDown,
  MoreVertical,
  Pencil,
  Trash2,
  Filter,
  X,
} from "lucide-react";
import { useFormik } from "formik";
import DeleteModal from "../../components/delete_modal";
import ROUTES from "../../config/routes";

const OrganizationContractList = () => {
  const navigate = useNavigate();
  const [openMenuId, setOpenMenuId] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (clientId) => {
    setOpenMenuId(openMenuId === clientId ? null : clientId);
  };

  const confirmDelete = async () => {
    /*const token = loginResult.token;
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        setLoading(true);*/
  };

  const handleDeleteClick = (user) => {
    setDeletingUser(user);
  };

  const handleCloseModal = () => {
    setDeletingUser(null);
  };

  const handleFilter = async () => {
    setFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const formik = useFormik({
    initialValues: { user_role: "" },
    initialErrors: {
      user_role: "User role is required",
    },
    validate: (values) => {
      const errors = {};
      if (!values.user_role) {
        errors.user_role = "User role is required";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      const modifiedValues = {
        category: values.user_role,
      };
      //handleSubmit(modifiedValues);
      setSubmitting(false);
    },
  });

  const openAddOrganizationContract = () => {
    navigate(ROUTES.ORGANIZATIONS.CONTRACTS.CREATE);
  };

  const [clients] = useState([
    {
      id: 1,
      clientId: "Shashank",
      clientName: "9898989999",
      registeredBusiness: "Admin",
      status: "Active",
    },
    {
      id: 2,
      clientId: "Shashank",
      clientName: "9898989999",
      registeredBusiness: "Admin",
      status: "Active",
    },
    {
      id: 3,
      clientId: "Shashank",
      clientName: "9898989999",
      registeredBusiness: "Admin",
      status: "Active",
    },
    {
      id: 4,
      clientId: "Shashank",
      clientName: "9898989999",
      registeredBusiness: "Admin",
      status: "Active",
    },
    {
      id: 5,
      clientId: "Shashank",
      clientName: "9898989999",
      registeredBusiness: "Admin",
      status: "Active",
    },
  ]);

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Contracts</h1>
          <p className="text-sm text-gray-500">
            Organization Control - Contracts
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm">
          <div className="p-4 flex justify-between items-center">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search..."
                className="pl-10 pr-4 py-2 bg-gray-100 rounded-lg w-80 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="flex gap-3">
              <button
                onClick={handleFilter}
                className="px-4 py-2 border border-gray-300 text-gray-600 rounded-lg hover:bg-gray-50 flex items-center gap-2 text-sm"
              >
                <Filter className="w-5 h-5" />
                Filter
              </button>
              <button className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 text-sm">
                <Download className="h-5 w-5" />
                Download
              </button>
              <button
                onClick={() => openAddOrganizationContract()}
                className="flex items-center gap-2 px-4 py-2 text-white rounded-lg bg-blue-600 hover:bg-blue-700 text-sm"
              >
                <span className="text-lg">+</span>
                Add Contract
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    S.NO.
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    NAME
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    PHONE NUMBER
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    EMAIL
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    ROLE
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    STATUS
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    ACTIONS
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {clients.map((client) => (
                  <tr key={client.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {client.id}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {client.clientId}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {client.clientName}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {client.clientName}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {client.registeredBusiness}
                    </td>
                    <td className="px-6 py-4">
                      <span className="px-2 py-1 text-xs font-medium text-green-600 bg-green-100 rounded-full">
                        {client.status}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <div
                        className="flex justify-center relative"
                        ref={menuRef}
                      >
                        <button
                          onClick={() => toggleMenu(client.id)}
                          className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
                        >
                          <MoreVertical className="h-5 w-5" />
                        </button>

                        {openMenuId === client.id && (
                          <div
                            className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                            style={{
                              transform: "translateY(calc(-50% + 2.5rem))",
                              top: "50%",
                            }}
                          >
                            <div className="py-1">
                              <button
                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                                onClick={() => {
                                  console.log("Edit clicked for:", client.id);
                                  toggleMenu(client.id);
                                }}
                              >
                                <Pencil className="h-4 w-4 mr-2" />
                                Edit
                              </button>
                              <button
                                className="flex items-center px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full"
                                onClick={() => {
                                  console.log("Delete clicked for:", client.id);
                                  //handleDeleteClick(client);
                                  //toggleMenu(client.id);
                                }}
                              >
                                <Trash2 className="h-4 w-4 mr-2" />
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this organization?"}
        isOpen={deletingUser}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default OrganizationContractList;
